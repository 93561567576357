import * as singleSpa from 'single-spa';
import { constructApplications, constructLayoutEngine, constructRoutes } from 'single-spa-layout';
import { Client as Styletron } from 'styletron-engine-atomic';
import { createBrowserHistory, createHashHistory } from 'history';
import { QueryClient } from 'react-query';

// global styletron engine for generating css classes
const engine = new Styletron({ prefix: 'vmsnext_' });
const browserHistory = createBrowserHistory();
const hashHistory = createHashHistory();
const queryClient = new QueryClient();

// function showWhenAnyOf(routes) {
//     return (location): boolean => {
//         return routes.some((route) => location.pathname === route);
//     };
// }

const portfolioFilteredPaths = ['/portfolio-management', '/valuations', '/bid-engage'];

function showWhenPrefix(routes: string[]) {
    return (location: Location): boolean => {
        return routes.some((route) => location.pathname.startsWith(route));
    };
}

function showExcept(routes: string[]) {
    return (location: Location): boolean => {
        return routes.every((route) => location.pathname !== route);
    };
}

singleSpa.registerApplication(
    'login',
    () => System.import('@samc/single-spa-authentication') as never,
    () => true,
    {
        styletronEngine: engine,
        browserHistory,
        hashHistory,
        queryClient,
    },
);

singleSpa.registerApplication(
    'workbench',
    () => System.import('@samc/single-spa-workbench') as never,
    showExcept(['/login', '/logout', '/']),
    {
        styletronEngine: engine,
        browserHistory,
        hashHistory,
        queryClient,
    },
);

singleSpa.registerApplication(
    'appbar',
    () => System.import('@samc/single-spa-appbar') as never,
    showExcept(['/login', '/logout']),
    {
        styletronEngine: engine,
        browserHistory,
        hashHistory,
        queryClient,
    },
);
singleSpa.registerApplication(
    'tenant-selector',
    () => System.import('@samc/single-spa-tenant-selector') as never,
    showExcept(['/login', '/logout', '/']),
    {
        styletronEngine: engine,
    },
);
singleSpa.registerApplication(
    'portfolio-selector',
    () => System.import('@samc/single-spa-portfolio-selector') as never,
    showWhenPrefix(portfolioFilteredPaths),
    {
        styletronEngine: engine,
        browserHistory,
        hashHistory,
        queryClient,
    },
);

/**
 * ##############################################################################################
 * ##################################### BODY APPLICATIONS ######################################
 * ##############################################################################################
 *
 * This section contains all of the SPAs that fall inside of the bounds of the appbar/workbench,
 * the "body" of VMSNext.
 *
 */
const screenConfigAppConfig = {
    type: 'application',
    name: '@samc/single-spa-screen-config',
    props: {
        styletronEngine: engine,
        browserHistory,
        hashHistory,
        queryClient,
    },
};

const pickListAppConfig = {
    type: 'application',
    name: '@samc/single-spa-picklist',
    props: {
        styletronEngine: engine,
        browserHistory,
        hashHistory,
        queryClient,
    },
};

const companyCatalogAppConfig = {
    type: 'application',
    name: '@samc/single-spa-company-catalog',
    props: {
        styletronEngine: engine,
        browserHistory,
        hashHistory,
        queryClient,
    },
};

const reportingAppConfig = {
    type: 'application',
    name: '@samc/single-spa-reports',
    props: {
        styletronEngine: engine,
        browserHistory,
        hashHistory,
        queryClient,
    },
};

const fileDownloadAppConfig = {
    type: 'application',
    name: '@samc/single-spa-file-download',
    props: {
        styletronEngine: engine,
        browserHistory,
        hashHistory,
        queryClient,
    },
};

const userDefinedFieldsAppConfig = {
    type: 'application',
    name: '@samc/single-spa-user-defined-fields',
    props: {
        styletronEngine: engine,
        browserHistory,
        hashHistory,
        queryClient,
    },
};

const userManagementAppConfig = {
    type: 'application',
    name: '@samc/single-spa-user-management',
    props: {
        styletronEngine: engine,
        browserHistory,
        hashHistory,
        queryClient,
    },
};

const valuationAppConfig = {
    type: 'application',
    name: '@samc/single-spa-valuation',
    props: {
        styletronEngine: engine,
        browserHistory,
        hashHistory,
        queryClient,
    },
};

const bidengageAppConfig = {
    type: 'application',
    name: '@samc/single-spa-bidengage',
    props: {
        styletronEngine: engine,
        browserHistory,
        hashHistory,
    },
};

const userLandingAppConfig = {
    type: 'application',
    name: '@samc/single-spa-user-landing',
    props: {
        styletronEngine: engine,
        browserHistory,
        hashHistory,
        queryClient,
    },
};

const screenConfigPaths = [
    '/portfolio-management',
    '/administration/portfolios',
    '/administration/document-types',
    '/configurations',
    '/roles-and-entitlements',
    '/screen',
    '/currency-exchange',
    '/reference-documents',
];
const pickListPaths = ['/administration/picklists'];
const companyCatalogPaths = ['/company-catalog'];
const reportingPaths = ['/reporting', '/reports'];
const fileDownloadPaths = ['/download'];
const userDefinedFieldsPaths = ['/administration/custom-fields'];
const userManagementPaths = ['/administration/users'];
const globalUserManagementPaths = ['/global-management/user-management'];
const valuationPaths = ['/valuations'];
const userLandingPaths = ['/'];
const bidEngagePaths = ['/bid-engage'];

const container = document.getElementById('samc-current-spa') as HTMLDivElement;

const resolvedRoutes = constructRoutes({
    containerEl: container,
    routes: [
        ...screenConfigPaths.map((r) => ({
            type: 'route',
            path: r,
            routes: [screenConfigAppConfig],
        })),
        ...pickListPaths.map((r) => ({
            type: 'route',
            path: r,
            routes: [pickListAppConfig],
        })),
        ...companyCatalogPaths.map((r) => ({
            type: 'route',
            path: r,
            routes: [companyCatalogAppConfig],
        })),
        ...reportingPaths.map((r) => ({
            type: 'route',
            path: r,
            routes: [reportingAppConfig],
        })),
        ...fileDownloadPaths.map((r) => ({
            type: 'route',
            path: r,
            routes: [fileDownloadAppConfig],
        })),
        ...userDefinedFieldsPaths.map((r) => ({
            type: 'route',
            path: r,
            routes: [userDefinedFieldsAppConfig],
        })),
        ...userManagementPaths.map((r) => ({
            type: 'route',
            path: r,
            routes: [userManagementAppConfig],
        })),
        ...globalUserManagementPaths.map((r) => ({
            type: 'route',
            path: r,
            routes: [userManagementAppConfig],
        })),
        ...valuationPaths.map((r) => ({
            type: 'route',
            path: r,
            routes: [valuationAppConfig],
        })),
        ...userLandingPaths.map((r) => ({
            type: 'route',
            path: r,
            exact: true,
            routes: [userLandingAppConfig],
        })),
        ...bidEngagePaths.map((r) => ({
            type: 'route',
            path: r,
            exact: true,
            routes: [bidengageAppConfig],
        })),
    ],
});

const apps = constructApplications({
    routes: resolvedRoutes,
    loadApp: (app) => System.import(app.name),
});

constructLayoutEngine({ routes: resolvedRoutes, applications: apps });
apps.forEach(singleSpa.registerApplication);

singleSpa.start();
